<template>
  <form @submit.prevent="handleSubmit">
    <div class="card">
      <div class="card-header">
        <h3 v-if="!existingEvent" class="card-title">Add New Event</h3>
        <h3 v-if="existingEvent" class="card-title">{{ event.title }} - #{{ event.eventId }}</h3>
        <div v-if="existingEvent" class="card-options">
          <button v-if="!editMode" class="btn btn-warning" @click="editEvent(true)">Edit</button>
          <button v-if="editMode" class="btn btn-secondary" @click="editEvent(false)">Cancel</button>
        </div>
      </div>
      <div class="card-body">
        <div class="row mb-3">
          <!-- Event Title -->
          <div class="col-md-6">
            <label class="form-label">Event Title</label>
            <input v-model="event.title" type="text" class="form-control" required :disabled="!editMode"/>
          </div>
          <!-- Slug -->
          <div class="col-md-6">
            <label class="form-label">Slug
              <IconInfoCircle
                  class="cursor-pointer"
                  style="margin-top: -9px"
                  data-bs-toggle="modal"
                  data-bs-target="#toolTipModal"
                  @click="toolTipMessage = 'This can only contain lowercase letters and hyphens, this is used for the URL. e.g. https://example.com/events/my-event-slug'">
              </IconInfoCircle>
            </label>
            <input v-model="event.slug" type="text" class="form-control" :disabled="!editMode" required/>
          </div>
        </div>

        <!-- Description -->
        <div class="mb-3">
          <label class="form-label">Description</label>
          <textarea :disabled="!editMode" v-model="event.description" class="form-control" required></textarea>
        </div>

        <div class="row mb-3">
          <!-- Price -->
          <div class="col-md-6">
            <label class="form-label">Price (pence)</label>
            <input :disabled="!editMode" v-model="event.price" type="number" class="form-control" required/>
          </div>

          <!-- Capacity -->
          <div class="col-md-6">
            <label class="form-label">Capacity</label>
            <input :disabled="!editMode" v-model="event.capacity" type="number" class="form-control" required/>
          </div>
        </div>

        <div class="row mt-6">
          <div class="card">
            <div class="row row-0">
              <div class="col-3">
                <img width="320" height="240" v-if="event.image" :src="event.image"/>
                <svg xmlns="http://www.w3.org/2000/svg" v-if="!event.image" class="w-100"
                     preserveAspectRatio="none" width="320" height="240" viewBox="0 0 400 200"
                     fill="transparent" stroke="var(--tblr-border-color, #b8cef1)">
                  <line x1="0" y1="0" x2="400" y2="200"></line>
                  <line x1="0" y1="200" x2="400" y2="0"></line>
                </svg>
              </div>
              <div class="col">
                <div class="card-body">
                  <div class="form-label">Event Image</div>
                  <div class="d-flex">
                    <input :disabled="!editMode" type="file" class="form-control flex-grow-1 me-2"
                           @change="onFileChange"/>
                    <div :disabled="!editMode" class="btn btn-primary" @click="uploadImage">Upload</div>
                  </div>
                  <div v-if="uploadStatus" class="alert alert-azure mt-2">
                    {{ uploadStatus }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer text-end">
        <button :disabled="!editMode" type="submit" class="btn btn-primary">Submit</button>
      </div>
    </div>
  </form>

  <div class="card mt-5" v-if="existingEvent">
    <div class="card-header">
      <h3 class="card-title">Event Sessions</h3>
      <div class="card-options">
        <button class="btn btn-primary" @click="addSession">Add New Session</button>
      </div>
    </div>
    <div class="card-body">
      <table class="table">
        <thead>
        <tr>
          <th scope="col">Start Date/Time</th>
          <th scope="col">End Date/Time</th>
          <th scope="col">Capacity</th>
          <th scope="col">Price (pence)</th>
          <th scope="col" class="w-1 text-end text-nowrap"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(session, index) in eventSessions" :key="index">
          <td v-if="!session.isNew">{{ formatDateTime(session.startDateTime) }}</td>
          <td v-if="!session.isNew">{{ formatDateTime(session.endDateTime) }}</td>
          <td v-if="!session.isNew">{{ session.capacity }}</td>
          <td v-if="!session.isNew">{{ session.price }}</td>
          <td v-if="session.isNew">
            <input v-model="session.startDateTime" type="datetime-local" class="form-control" />
          </td>
          <td v-if="session.isNew">
            <input v-model="session.endDateTime" type="datetime-local" class="form-control" />
          </td>
          <td v-if="session.isNew">
            <input type="number" v-model="session.capacity" placeholder="Capacity" class="form-control" />
          </td>
          <td v-if="session.isNew">
            <input type="number" v-model="session.price" placeholder="Price" class="form-control" />
          </td>
          <td class="text-end text-nowrap">
            <router-link :to="`/events/session/${session.eventSessionId}`" class="btn btn-icon btn-ghost-cyan"><IconInfoCircle></IconInfoCircle></router-link>
            <button v-if="session.isNew" class="btn btn-icon btn-ghost-success" @click="saveSession(index)">
              <IconDeviceFloppy></IconDeviceFloppy>
            </button>
            <button v-else class="btn btn-icon btn-ghost-danger" data-bs-toggle="modal"
                    data-bs-target="#deleteSessionModal" @click="askToDeleteSession(session)">
              <IconTrash></IconTrash>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <DangerModal
      ref="modalDeleteSession"
      modalId="deleteSessionModal"
      title="Delete Session"
      :message="deleteMessage"
      closeButton="Cancel"
      submitButton="Delete"
      @submit="handleSessionDelete"
      :errorMessage="deleteErrorMessage"
  ></DangerModal>

  <InfoModal modal-id="toolTipModal" :message="toolTipMessage"></InfoModal>
</template>

<script>
import { IconInfoCircle, IconDeviceFloppy, IconTrash } from "@tabler/icons-vue";
import api from '@/services/api';
import InfoModal from "@/components/InfoModal.vue";
import DangerModal from "@/components/DangerModal.vue";

export default {
  name: 'EventView',
  data() {
    return {
      event: {
        title: '',
        slug: '',
        description: '',
        price: null,
        capacity: null,
        image: '',
      },
      editMode: true,
      existingEvent: false,
      eventSessions: [],
      eventId: null,
      selectedFile: null,
      uploadStatus: '',
      toolTipMessage: '',
      deleteMessage: '',
      deleteErrorMessage: '',
      sessionToDelete: null,
    };
  },
  async mounted() {
    if (this.$route.params.eventId) {
      this.existingEvent = true;
      this.eventId = this.$route.params.eventId;
      this.editMode = false;
      await this.getExistingEvent();
    } else {
      document.title = "Add New Event | CarneCloud";
    }
  },
  methods: {
    editEvent(a) {
      this.editMode = a;
    },
    onFileChange(event) {
      this.selectedFile = event.target.files[0];
    },
    async uploadImage() {
      if (!this.selectedFile) {
        this.uploadStatus = 'No file selected';
        return;
      }

      const formData = new FormData();
      formData.append('image', this.selectedFile);
      try {
        const response = await api.post('/api/dam/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        this.uploadStatus = 'Upload successful: ' + response.data.url;
        this.event.image = response.data.url;
      } catch (error) {
        this.uploadStatus = 'Upload failed: ' + error.response.data.error;
      }
    },
    addSession() {
      this.eventSessions.unshift({
        eventSessionId: null,
        eventId: parseInt(this.eventId),
        startDateTime: '',
        endDateTime: '',
        capacity: this.event.capacity,
        price: this.event.price,
        isNew: true
      });
      this.$forceUpdate();
    },
    async saveSession(index) {
      const session = this.eventSessions[index];
      if (session.startDateTime && session.endDateTime && session.capacity && session.price) {
        try {
          const response = await api.post('/api/events/session', {
            eventId: this.eventId,
            startDateTime: new Date(session.startDateTime).getTime(),
            endDateTime: new Date(session.endDateTime).getTime(),
            capacity: parseInt(session.capacity),
            price: parseInt(session.price)
          });
          console.log('Session added:', response.data);
          await this.getEventSessions();
        } catch (error) {
          console.error('Error posting session:', error);
        }
      }
    },
    askToDeleteSession(session) {
      this.deleteMessage = `Are you sure you want to delete this session?`;
      this.sessionToDelete = session.eventSessionId;
    },
    async handleSessionDelete() {
      if (this.sessionToDelete) {
        try {
          await api.delete(`/api/events/session/${this.sessionToDelete}`);
          await this.getEventSessions();
          this.$refs.modalDeleteSession.$refs.closeButton.click();
          this.sessionToDelete = null;
          this.deleteMessage = '';
          this.deleteErrorMessage = '';
        } catch (e) {
          this.deleteErrorMessage = e.message;
        }
      }
    },
    async getEventSessions() {
      try {
        const response = await api.get(`/api/events/sessions/${this.eventId}`);
        this.eventSessions = response.data;
      } catch (error) {
        console.error('Error fetching event sessions:', error);
      }
    },
    async getExistingEvent() {
      try {
        const [eventResp, sessionResp] = await Promise.all([
          api.get(`/api/events/${this.eventId}`),
          api.get(`/api/events/sessions/${this.eventId}`)
        ]);
        this.event = eventResp.data;
        this.eventSessions = sessionResp.data;
        document.title = `${this.event.title} | CarneCloud`;
      } catch (error) {
        console.error('Error fetching event data:', error);
      }
    },
    async handleSubmit() {
      if (this.existingEvent) {
        if (this.editMode) {
          try {
            await api.put(`/api/events/${this.eventId}`, this.event);
            this.editMode = false;
            await this.getExistingEvent();
          } catch (error) {
            console.error('Error updating event:', error.response ? error.response.data : error.message);
          }
        }
      } else {
        try {
          const response = await api.post('/api/events', this.event);
          console.log('Event created with ID:', response.data.eventId);
          this.$router.push('/events/');
        } catch (error) {
          console.error('Error creating event:', error.response ? error.response.data : error.message);
        }
      }
    },
    formatDateTime(timestamp) {
      return new Date(timestamp).toLocaleString("en-GB");
    }
  },
  components: {
    DangerModal,
    InfoModal,
    IconInfoCircle,
    IconDeviceFloppy,
    IconTrash
  }
};
</script>