<template>
  <BasePage>
    <div class="container">
      <div class="mb-4">
        <button @click="showAddForm = true" class="btn btn-primary">Add New FAQ</button>
      </div>
      <div v-if="showAddForm" class="card mb-4">
        <div class="card-header">
          <h3 class="card-title">Add New FAQ</h3>
        </div>
        <div class="card-body">
          <input v-model="newFaqTitle" type="text" class="form-control mb-2" placeholder="FAQ Title" />
          <textarea v-model="newFaqText" class="form-control mb-2" placeholder="FAQ Text"></textarea>
        </div>
        <div class="card-footer">
          <button @click="addFaq" class="btn btn-success me-2">Save</button>
          <button @click="showAddForm = false" class="btn btn-secondary">Cancel</button>
        </div>
      </div>
      <div v-for="faq in faqs" :key="faq.faqId" class="card mt-3 mb-3">
        <div class="card-header">
          <div v-if="editing !== faq.faqId" class="card-title">{{faq.faqTitle}}</div>
          <input v-if="editing === faq.faqId" type="text" class="form-control mx-6" v-model="editedTitle" />
          <div class="card-options">
            <div v-if="editing !== faq.faqId" @click="startEditing(faq)" class="btn btn-outline-primary btn-sm cursor-pointer me-2">
              <IconEdit></IconEdit>
            </div>
            <div v-if="editing === faq.faqId" @click="cancelEditing" class="btn btn-outline-danger btn-sm cursor-pointer me-2">
              <IconCircleX></IconCircleX>
            </div>
            <div @click="deleteFaq(faq.faqId)" class="btn btn-outline-danger btn-sm cursor-pointer">
              <IconTrash></IconTrash>
            </div>
          </div>
        </div>
        <div class="card-body">
          <span v-if="editing !== faq.faqId">{{faq.faqText}}</span>
          <textarea class="form-control" v-if="editing === faq.faqId" v-model="editedText"></textarea>
        </div>
        <div class="card-footer d-flex" v-if="editing === faq.faqId">
          <button @click="saveFaq" class="btn btn-primary">Save</button>
        </div>
      </div>
    </div>
  </BasePage>
</template>

<script>
document.title = "FAQ | CarneCloud"
import api from '@/services/api'
import BasePage from '@/components/BasePage.vue';  // Adjust the import path if necessary
import { IconEdit, IconCircleX, IconTrash } from '@tabler/icons-vue'

export default {
  name: 'FAQPage',
  components: {
    BasePage,
    IconEdit,
    IconCircleX,
    IconTrash
  },
  data() {
    return {
      faqs: [],
      editing: null,
      editedTitle: "",
      editedText: "",
      showAddForm: false,
      newFaqTitle: "",
      newFaqText: ""
    };
  },
  async mounted() {
    await this.getFaq();
  },
  methods: {
    async getFaq() {
      try {
        const resp = await api.get('/api/faq');
        this.faqs = resp.data;
      } catch (e) {
        console.error('Error fetching FAQs:', e.message);
      }
    },
    startEditing(faq) {
      this.editing = faq.faqId;
      this.editedTitle = faq.faqTitle;
      this.editedText = faq.faqText;
    },
    cancelEditing() {
      this.editing = null;
      this.editedTitle = "";
      this.editedText = "";
    },
    async saveFaq() {
      try {
        await api.put(`/api/faq/${this.editing}`, {
          faqTitle: this.editedTitle,
          faqText: this.editedText
        });
        await this.getFaq();
        this.cancelEditing();
      } catch (e) {
        console.error('Error saving FAQ:', e.message);
      }
    },
    async addFaq() {
      try {
        await api.post('/api/faq', {
          faqTitle: this.newFaqTitle,
          faqText: this.newFaqText
        });
        await this.getFaq();
        this.showAddForm = false;
        this.newFaqTitle = "";
        this.newFaqText = "";
      } catch (e) {
        console.error('Error adding FAQ:', e.message);
      }
    },
    async deleteFaq(faqId) {
      if (confirm('Are you sure you want to delete this FAQ?')) {
        try {
          await api.delete(`/api/faq/${faqId}`);
          await this.getFaq();
        } catch (e) {
          console.error('Error deleting FAQ:', e.message);
        }
      }
    }
  },
};
</script>