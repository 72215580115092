<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">Delivery Settings</h3>
      <div class="card-options">
        <button class="btn btn-primary" @click="openAddModal">Add Delivery Date</button>
      </div>
    </div>
    <div class="card-body">
      <table class="table table-vcenter card-table">
        <thead>
        <tr>
          <th>Date Text</th>
          <th>Date Value</th>
          <th>Capacity</th>
          <th>Available Capacity</th>
          <th class="w-1"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="date in deliveryDates" :key="date.deliveryDateId">
          <td>{{ date.deliveryDateText }}</td>
          <td>{{ date.deliveryDateValue }}</td>
          <td>{{ date.deliveryDateCapacity }}</td>
          <td>{{ date.availableCapacity }}</td>
          <td>
            <div class="btn-list flex-nowrap">
              <button class="btn btn-icon btn-ghost-primary" @click="editDeliveryDate(date)">
                <IconEdit />
              </button>
              <button class="btn btn-icon btn-ghost-danger" @click="confirmDeleteDeliveryDate(date)">
                <IconTrash />
              </button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Add/Edit Delivery Date Modal -->
  <div class="modal modal-blur fade" id="deliveryDateModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ isEditing ? 'Edit' : 'Add' }} Delivery Date</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form @submit.prevent="handleDeliveryDateSubmit">
          <div class="modal-body">
            <div v-if="errorMessage" class="alert alert-danger" role="alert">
              {{ errorMessage }}
            </div>
            <div class="mb-3">
              <label class="form-label">Date Text</label>
              <input type="text" class="form-control" v-model="formData.deliveryDateText" required>
            </div>
            <div class="mb-3">
              <label class="form-label">Date Value</label>
              <input type="text" class="form-control" v-model="formData.deliveryDateValue" required>
            </div>
            <div class="mb-3">
              <label class="form-label">Capacity</label>
              <input type="number" class="form-control" v-model.number="formData.deliveryDateCapacity" required>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-link link-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="submit" class="btn btn-primary ms-auto">
              {{ isEditing ? 'Update' : 'Add' }} Delivery Date
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- Delete Confirmation Modal -->
  <DangerModal
      ref="deleteModal"
      modalId="deleteDeliveryDateModal"
      title="Delete Delivery Date"
      :message="deleteMessage"
      closeButton="Cancel"
      submitButton="Delete"
      @submit="handleDeleteDeliveryDate"
      :errorMessage="deleteErrorMessage"
  ></DangerModal>
</template>

<script>
import api from '@/services/api';
import { IconEdit, IconTrash } from '@tabler/icons-vue';
import DangerModal from "@/components/DangerModal.vue";

export default {
  name: 'DeliverySettings',
  components: {
    IconEdit,
    IconTrash,
    DangerModal
  },
  data() {
    return {
      deliveryDates: [],
      formData: {
        deliveryDateText: '',
        deliveryDateValue: '',
        deliveryDateCapacity: 100
      },
      isEditing: false,
      editingId: null,
      deleteMessage: '',
      deletingId: null,
      errorMessage: '',
      deleteErrorMessage: ''
    }
  },
  async created() {
    await this.fetchDeliveryDates();
  },
  methods: {
    async fetchDeliveryDates() {
      try {
        const response = await api.get('/api/collection/delivery');
        this.deliveryDates = response.data;
      } catch (error) {
        console.error('Error fetching delivery dates:', error);
        this.errorMessage = 'Failed to fetch delivery dates. Please try again.';
      }
    },
    openAddModal() {
      this.resetForm();
      this.isEditing = false;
      this.openModal();
    },
    editDeliveryDate(date) {
      this.isEditing = true;
      this.editingId = date.deliveryDateId;
      this.formData = { ...date };
      this.openModal();
    },
    openModal() {
      const modal = document.getElementById('deliveryDateModal');
      const modalInstance = new window.Modal(modal);
      modalInstance.show();
    },
    confirmDeleteDeliveryDate(date) {
      this.deletingId = date.deliveryDateId;
      this.deleteMessage = `Are you sure you want to delete the delivery date "${date.deliveryDateText}"?`;
      this.$refs.deleteModal.show();
    },
    async handleDeliveryDateSubmit() {
      try {
        if (this.isEditing) {
          await api.put(`/api/delivery/dates/${this.editingId}`, this.formData);
        } else {
          await api.post('/api/delivery/dates', this.formData);
        }
        await this.fetchDeliveryDates();
        this.closeModal();
        this.resetForm();
      } catch (error) {
        console.error('Error submitting delivery date:', error);
        this.errorMessage = 'Failed to save delivery date. Please try again.';
      }
    },
    async handleDeleteDeliveryDate() {
      try {
        await api.delete(`/api/delivery/dates/${this.deletingId}`);
        await this.fetchDeliveryDates();
        this.$refs.deleteModal.hide();
        this.deleteErrorMessage = '';
      } catch (error) {
        console.error('Error deleting delivery date:', error);
        this.deleteErrorMessage = 'Failed to delete delivery date. Please try again.';
      }
    },
    resetForm() {
      this.formData = {
        deliveryDateText: '',
        deliveryDateValue: '',
        deliveryDateCapacity: 100
      };
      this.isEditing = false;
      this.editingId = null;
      this.errorMessage = '';
    },
    closeModal() {
      const modal = document.getElementById('deliveryDateModal');
      const modalInstance = window.Modal.getInstance(modal);
      modalInstance.hide();
    }
  }
}
</script>