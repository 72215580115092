<template>
  <div class="card mt-2">
    <div class="card-header">
      <h3 class="card-title">Events</h3>
      <div class="card-actions">
        <router-link to="/events/new" class="btn btn-primary">
          Add new event
        </router-link>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-vcenter card-table">
          <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Default Price</th>
            <th>Default Capacity</th>
            <th>Sessions</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="event in eventData" :key="event.eventId">
            <td><router-link :to="`/events/${event.eventId}`" class="link-underline">{{ event.title }}</router-link></td>
            <td>{{ truncateDescription(event.description) }}</td>
            <td>£{{ (event.price / 100).toFixed(2) }}</td>
            <td>{{ event.capacity }}</td>
            <td>{{ event.eventSessions ? event.eventSessions.length : 0 }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventsTable',
  props: {
    eventData: {
      type: Array,
      required: true
    }
  },
  methods: {
    truncateDescription(description) {
      return description.length > 50 ? description.substring(0, 47) + '...' : description;
    }
  }
};
</script>