<template>
  <BasePage>
    <div class="container">
      <EventOverview :events="events || []" />
      <EventsTable id="Events" :eventData="events" @event-updated="updateData"></EventsTable>
    </div>
  </BasePage>
</template>

<script>
import BasePage from '@/components/BasePage.vue';  // Adjust the import path if necessary
import EventOverview from "@/components/EventOverview.vue";
import EventsTable from "@/components/EventsTable.vue";
import api from '@/services/api';

export default {
  name: 'EventPage',
  components: {
    BasePage,
    EventOverview,
    EventsTable
  },
  data() {
    return {
      events: []
    };
  },
  async created() {
    document.title = "Events | CarneCloud";
    await this.fetchData();
  },
  methods: {
    async updateData() {
      await this.fetchData();
    },
    async fetchData() {
      try {
        const eventsRes = await api.get('/api/events');
        this.events = eventsRes.data;
        return true;
      } catch (error) {
        console.error('Error fetching event data:', error);
        return false;
      }
    },
  },
};
</script>