<template>
  <BasePage>
    <div class="container-xl">
      <div class="page-header d-print-none">
        <div class="row align-items-center">
          <div class="col">
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <ul class="nav nav-tabs card-header-tabs" data-bs-toggle="tabs">
            <li class="nav-item">
              <a href="#collection" class="nav-link" :class="{ active: activeTab === 'collection' }" @click.prevent="activeTab = 'collection'">Collection Settings</a>
            </li>
            <li class="nav-item">
              <a href="#delivery" class="nav-link" :class="{ active: activeTab === 'delivery' }" @click.prevent="activeTab = 'delivery'">Delivery Settings</a>
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane" :class="{ active: activeTab === 'collection' }" id="collection">
              <CollectionSettings></CollectionSettings>
            </div>
            <div class="tab-pane" :class="{ active: activeTab === 'delivery' }" id="delivery">
              <DeliverySettings></DeliverySettings>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BasePage>
</template>

<script>
document.title = "Settings | CarneCloud"
import BasePage from '@/components/BasePage.vue';
import CollectionSettings from "@/components/CollectionSettings.vue";
import DeliverySettings from "@/components/DeliverySettings.vue";

export default {
  name: 'SettingsPage',
  components: {
    BasePage,
    CollectionSettings,
    DeliverySettings,
  },
  data() {
    return {
      activeTab: 'collection',
    };
  },
  created() {
    // Any initialization logic can go here
  },
  methods: {
    // Add any necessary methods here
  },
};
</script>

<style scoped>
/* Add any component-specific styles here if needed */
</style>