<template>
  <div class="modal modal-blur fade show" id="modal-import" tabindex="-1" role="dialog" aria-modal="true" style="display: block;">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Import Products</h5>
          <button type="button" class="btn-close" @click="$emit('close')"></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label class="form-label">Upload CSV File</label>
            <input type="file" class="form-control" @change="handleFileUpload" accept=".csv" />
          </div>
          <div v-if="importResults.length > 0" class="mt-3">
            <h6>Import Results:</h6>
            <div class="table-responsive">
              <table class="table table-vcenter card-table">
                <thead>
                <tr>
                  <th>SKU</th>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="result in importResults" :key="result.sku">
                  <td>{{ result.sku }}</td>
                  <td>{{ result.name }}</td>
                  <td>
                                            <span :class="getStatusBadgeClass(result.status)">
                                                {{ result.status }}
                                            </span>
                  </td>
                  <td>
                    <div v-if="result.status === 'exists'" class="btn-group">
                      <button class="btn btn-sm btn-outline-primary" @click="resolveProduct(result.sku, 'override')">Override</button>
                      <button class="btn btn-sm btn-outline-secondary" @click="resolveProduct(result.sku, 'ignore')">Ignore</button>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-link link-secondary" @click="$emit('close')">
            Cancel
          </button>
          <button type="button" class="btn btn-primary ms-auto" @click="importProducts" :disabled="!selectedFile">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /><path d="M12 11v6" /><path d="M9.5 13.5l2.5 -2.5l2.5 2.5" /></svg>
            Import
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api';

export default {
  name: 'ImportProductsModal',
  data() {
    return {
      selectedFile: null,
      importResults: []
    };
  },
  methods: {
    handleFileUpload(event) {
      this.selectedFile = event.target.files[0];
    },
    async importProducts() {
      if (!this.selectedFile) return;

      const formData = new FormData();
      formData.append('file', this.selectedFile);

      try {
        const response = await api.post('/api/products/import', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        this.importResults = response.data;
      } catch (error) {
        console.error('Error importing products:', error);
        // Handle error (e.g., show error message to user)
      }
    },
    async resolveProduct(sku, action) {
      try {
        const formData = new FormData();
        formData.append('file', this.selectedFile);

        await api.post('/api/products/import/resolve', {
          sku,
          action,
          file: formData
        }, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        // Update the import results
        this.importResults = this.importResults.map(result => {
          if (result.sku === sku) {
            return { ...result, status: action === 'override' ? 'updated' : 'ignored' };
          }
          return result;
        });

        if (this.importResults.every(result => ['inserted', 'updated', 'ignored'].includes(result.status))) {
          this.$emit('import-complete');
        }
      } catch (error) {
        console.error('Error resolving product:', error);
        // Handle error (e.g., show error message to user)
      }
    },
    getStatusBadgeClass(status) {
      switch (status) {
        case 'inserted':
          return 'badge bg-success';
        case 'exists':
          return 'badge bg-warning';
        case 'error':
          return 'badge bg-danger';
        case 'updated':
          return 'badge bg-info';
        case 'ignored':
          return 'badge bg-secondary';
        default:
          return 'badge bg-primary';
      }
    }
  }
};
</script>

<style scoped>
/* Add any additional styles here */
</style>