<template>
  <BasePage>
    <template #header>
      <h1 class="page-title">{{ pageTitle }}</h1>
    </template>

    <div class="container">
      <div v-if="session">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Event Session Details</h3>
          </div>
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-md-6">
                <strong>Start Date/Time:</strong> {{ formatDateTime(session.startDateTime) }}
              </div>
              <div class="col-md-6">
                <strong>End Date/Time:</strong> {{ formatDateTime(session.endDateTime) }}
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-6">
                <strong>Capacity:</strong> {{ session.capacity }}
              </div>
              <div class="col-md-6">
                <strong>Remaining Capacity:</strong> {{ remainingCapacity }}
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-6">
                <strong>Price:</strong> £{{ (session.price / 100).toFixed(2) }}
              </div>
            </div>
          </div>
        </div>

        <div class="card mt-4">
          <div class="card-header">
            <h3 class="card-title">Registered Participants</h3>
            <div class="card-actions">
              <label class="form-check form-switch me-3">
                <input class="form-check-input" type="checkbox" v-model="showOnlyConfirmed">
                <span class="form-check-label">Show only confirmed</span>
              </label>
              <button data-bs-toggle="modal"
                      data-bs-target="#registerParticipantModal" class="btn btn-primary"  @click="openRegisterModal">
                Register New Participant
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-vcenter card-table">
                <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Status</th>
                  <th>Ticket Count</th>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="participant in filteredParticipants" :key="participant.eventRegistrationId">
                  <td>{{ participant.firstName }} {{ participant.lastName }}</td>
                  <td>{{ participant.email }}</td>
                  <td>{{ participant.phone }}</td>
                  <td>
                      <span :class="getStatusBadgeClass(participant.status)">
                        {{ participant.status }}
                      </span>
                  </td>
                  <td>{{ participant.ticketCount }}</td>
                  <td>
                    <button class="btn btn-sm btn-primary me-2" @click="editParticipant(participant)">
                      Edit
                    </button>
                    <button v-if="participant.status === 'paid'" class="btn btn-sm btn-secondary" @click="resendConfirmation(participant)">
                      Resend Confirmation
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!-- Edit Participant Modal -->
        <div v-if="showEditModal" class="modal modal-blur fade show" id="editParticipantModal" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Edit Participant</h5>
                <button type="button" class="btn-close" @click="closeEditModal" aria-label="Close"></button>
              </div>
              <div class="modal-body" v-if="editingParticipant">
                <div class="mb-3">
                  <label class="form-label">First Name</label>
                  <input type="text" class="form-control" v-model="editingParticipant.firstName">
                </div>
                <div class="mb-3">
                  <label class="form-label">Last Name</label>
                  <input type="text" class="form-control" v-model="editingParticipant.lastName">
                </div>
                <div class="mb-3">
                  <label class="form-label">Email</label>
                  <input type="email" class="form-control" v-model="editingParticipant.email">
                </div>
                <div class="mb-3">
                  <label class="form-label">Phone</label>
                  <input type="tel" class="form-control" v-model="editingParticipant.phone">
                </div>
                <div class="mb-3">
                  <label class="form-label">Status</label>
                  <select class="form-select" v-model="editingParticipant.status">
                    <option value="pending">Pending</option>
                    <option value="paid">Paid</option>
                    <option value="cancelled">Cancelled</option>
                  </select>
                </div>
                <div class="mb-3">
                  <label class="form-label">Ticket Count</label>
                  <input type="number" class="form-control" v-model="editingParticipant.ticketCount">
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-link link-secondary" @click="closeEditModal">Cancel</button>
                <button type="button" class="btn btn-primary ms-auto" @click="saveParticipant">Save changes</button>
              </div>
            </div>
          </div>
        </div>

        <!-- Register New Participant Modal -->
        <div v-if="showRegisterModal" class="modal modal-blur fade show" id="registerParticipantModal" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Register New Participant</h5>
                <button type="button" class="btn-close" @click="closeRegisterModal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="mb-3">
                  <label class="form-label">First Name</label>
                  <input type="text" class="form-control" v-model="newParticipant.firstName">
                </div>
                <div class="mb-3">
                  <label class="form-label">Last Name</label>
                  <input type="text" class="form-control" v-model="newParticipant.lastName">
                </div>
                <div class="mb-3">
                  <label class="form-label">Email</label>
                  <input type="email" class="form-control" v-model="newParticipant.email">
                </div>
                <div class="mb-3">
                  <label class="form-label">Phone</label>
                  <input type="tel" class="form-control" v-model="newParticipant.phone">
                </div>
                <div class="mb-3">
                  <label class="form-label">Ticket Count</label>
                  <input type="number" class="form-control" v-model="newParticipant.ticketCount">
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-link link-secondary" @click="closeRegisterModal">Cancel</button>
                <button type="button" class="btn btn-primary ms-auto" @click="registerParticipant">Register</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="alert alert-info" role="alert">
        Loading session details... <IconInfoCircle></IconInfoCircle>
      </div>
    </div>
  </BasePage>
</template>

<script>
import api from '@/services/api';
import BasePage from '@/components/BasePage.vue';
import {IconInfoCircle} from "@tabler/icons-vue";

export default {
  name: 'EventSessionView',
  components: {
    IconInfoCircle,
    BasePage,
  },
  data() {
    return {
      pageTitle: 'Event Session',
      session: null,
      participants: [],
      showOnlyConfirmed: false,
      editingParticipant: null,
      newParticipant: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        ticketCount: 1
      },
      showEditModal: false,
      showRegisterModal: false,
    };
  },
  computed: {
    remainingCapacity() {
      if (!this.session) return 0;
      const totalTickets = this.participants.reduce((sum, p) => sum + p.ticketCount, 0);
      return this.session.capacity - totalTickets;
    },
    filteredParticipants() {
      return this.showOnlyConfirmed
          ? this.participants.filter(p => p.status === 'paid')
          : this.participants;
    },
  },
  created() {
    this.fetchSessionData(this.$route.params.sessionId);
  },
  methods: {
    async fetchSessionData(sessionId) {
      try {
        await Promise.all([
          this.getSessionDetails(sessionId),
          this.getParticipants(sessionId),
        ]);
      } catch (error) {
        console.error('Error fetching session data:', error);
      }
    },
    async getSessionDetails(sessionId) {
      try {
        const response = await api.get(`/api/events/session/${sessionId}`);
        this.session = response.data;
      } catch (error) {
        console.error('Error fetching session details:', error);
      }
    },
    async getParticipants(sessionId) {
      try {
        const response = await api.get(`/api/events/session-participants/${sessionId}`);
        this.participants = response.data;
      } catch (error) {
        console.error('Error fetching participants:', error);
      }
    },
    formatDateTime(timestamp) {
      return new Date(parseInt(timestamp)).toLocaleString("en-GB");
    },
    getStatusBadgeClass(status) {
      switch (status) {
        case 'paid':
          return 'badge bg-success';
        case 'pending':
          return 'badge bg-warning';
        case 'cancelled':
          return 'badge bg-danger';
        default:
          return 'badge bg-secondary';
      }
    },
    editParticipant(participant) {
      this.editingParticipant = {...participant};
      this.showEditModal = true;
    },
    closeEditModal() {
      this.showEditModal = false;
      this.editingParticipant = null;
    },
    async saveParticipant() {
      try {
        await api.put(`/api/events/session-participant/${this.editingParticipant.eventRegistrationId}`, this.editingParticipant);
        const index = this.participants.findIndex(p => p.eventRegistrationId === this.editingParticipant.eventRegistrationId);
        if (index !== -1) {
          this.participants[index] = {...this.editingParticipant};
        }
        this.closeEditModal();
      } catch (error) {
        console.error('Error updating participant:', error);
      }
    },
    async resendConfirmation(participant) {
      try {
        await api.post('/api/eventRegistration/resend-confirmation', {guid: participant.guid});
        alert('Confirmation email resent successfully');
      } catch (error) {
        console.error('Error resending confirmation:', error);
        alert('Failed to resend confirmation email');
      }
    },
    openRegisterModal() {
      this.newParticipant = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        ticketCount: 1
      };
      this.showRegisterModal = true;
    },
    closeRegisterModal() {
      this.showRegisterModal = false;
    },
    async registerParticipant() {
      try {
        const response = await api.post('/api/eventRegistration/staff-registration', {
          ...this.newParticipant,
          sessionId: this.$route.params.sessionId
        });
        console.log('Participant registered:', response.data);
        this.closeRegisterModal();
        await this.getParticipants(this.$route.params.sessionId);
        // Show a success message
        alert('Participant registered successfully');
      } catch (error) {
        console.error('Error registering participant:', error);
        // Show an error message
        alert('Failed to register participant: ' + (error.response?.data?.error || error.message));
      }
    },
  },
};
</script>