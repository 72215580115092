<template>
  <BasePage>
    <template #header>
      <h1 class="page-title">{{ pageTitle }}</h1>
    </template>

    <div class="container">
      <EventView
          :eventId="eventId"
          @event-saved="onEventSaved"
          @event-deleted="onEventDeleted"
      />
    </div>
  </BasePage>
</template>

<script>
import BasePage from '@/components/BasePage.vue';
import EventView from '@/components/EventView.vue';

export default {
  name: 'EventViewPage',
  components: {
    BasePage,
    EventView
  },
  data() {
    return {
      eventId: null
    };
  },
  computed: {
    pageTitle() {
      return this.eventId ? 'Edit Event' : 'Create New Event';
    }
  },
  created() {
    // Get the eventId from the route params if it exists
    this.eventId = this.$route.params.eventId;
    document.title = `${this.pageTitle} | CarneCloud`;
  },
  methods: {
    onEventSaved() {
      // Handle successful save
      this.$router.push({ name: 'Events' }); // Redirect to events list
      // You might want to show a success message here
    },
    onEventDeleted() {
      // Handle successful delete
      this.$router.push({ name: 'Events' }); // Redirect to events list
      // You might want to show a success message here
    }
  }
};
</script>

<style scoped>
.page-title {
  margin-bottom: 1rem;
}

.container {
  padding: 1rem;
}
</style>