/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router';

import LoginPage from '@/pages/LoginPage.vue';
import HomePage from '@/pages/HomePage.vue'
import PimPage from "@/pages/PimPage.vue";
import NewProductPage from "@/pages/NewProductPage.vue";
import NotFoundPage from "@/pages/NotFound.vue"
import OrdersPage from "@/pages/OrdersPage.vue";
import OrderDetailsPage from "@/pages/OrderDetailsPage.vue";
import SettingsPage from "@/pages/SettingsPage.vue";
import faqPage from "@/pages/FaqPage.vue";
import ChatBotPage from "@/pages/ChatBotPage.vue";
import ReportsPage from "@/pages/ReportsPage.vue";
import LabelPage from "@/pages/LabelPage.vue";
import SlipsPage from "@/pages/SlipsPage.vue";
import DeliveryPage from "@/pages/DeliveryPage.vue";
import CollectedPage from "@/pages/CollectedPage.vue";
import EventPage from "@/pages/EventPage.vue";
import EventView from "@/pages/EventViewPage.vue";
import EventSessionView from "@/components/EventSessionView.vue";


const routes = [
    { path: '/', component: HomePage},
    { path: '/login', component: LoginPage, name: 'Login', props: true, query: true },
    { path: '/pim', component: PimPage, name: 'PIM', props: true },
    { path: '/pim/products/new', component: NewProductPage, name: 'NewProductPage', props: true },
    { path: '/pim/products/:productId', component: NewProductPage, name: 'ProductDetailsPage', props: true },
    { path: '/orders', component: OrdersPage, name: 'Orders', props: true },
    { path: '/orders/:orderId', component: OrderDetailsPage, name: 'OrderDetails', props: true },
    { path: '/configuration', component: SettingsPage, name: 'Configuration', props: true },
    { path: '/deliveries', component: DeliveryPage, name: 'Delivery', props: true },
    { path: '/collected', component: CollectedPage, name: 'Collected', props: true },
    { path: '/faq', component: faqPage, name: 'FAQ', props: true },
    { path: '/chatbot', component: ChatBotPage, name: 'ChatBot', props: true },
    { path: '/reports/:reportView', component: ReportsPage, name: 'ReportsPage', props: true },
    { path: '/reports/', component: ReportsPage, name: 'ReportsBasePage', props: true },
    { path: '/label/', component: LabelPage, name: 'LabelPage', props: true },
    { path: '/label/:sku', component: LabelPage, name: 'LabelPage', props: true },
    { path: '/label/:sku/:filter', component: LabelPage, name: 'LabelFilterPage', props: true },
    { path: '/slips/filter/:filter', component: SlipsPage, name: 'SlipsPage', props: true },
    { path: '/survey', component: SlipsPage, name: 'SurveyPage', props: true },
    { path: '/events', component: EventPage, name: 'Events', props: true },
    { path: '/events/new', component: EventView, name: 'NewEvent', props: true },
    { path: '/events/:eventId', component: EventView, name: 'EventDetails', props: true },
    { path: '/events/session/:sessionId', component: EventSessionView, name: 'EventSessionView', props: true },

    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFoundPage,
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
});
export default router;