<template>
  <div class="row row-cards">
    <div class="col-md-6 col-xl-3">
      <div class="card card-sm">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-auto">
              <span class="bg-blue text-white avatar">
                <IconSlice></IconSlice>
              </span>
            </div>
            <div class="col">
              <div class="font-weight-medium">
                {{ totalEvents }} Events
              </div>
              <div class="text-muted">
                {{ activeEvents }} Active
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Add more overview cards as needed -->
  </div>
</template>

<script>
import { IconSlice } from "@tabler/icons-vue";
export default {
  name: 'EventOverview',
  props: {
    events: {
      type: Array,
      required: true
    }
  },
  computed: {
    totalEvents() {
      return this.events.length;
    },
    activeEvents() {
      const now = Date.now();
      return this.events.filter(event =>
          event.eventSessions &&
          event.eventSessions.some(session => new Date(session.endDateTime) > now)
      ).length;
    }
  },
  components: {
    IconSlice
  }
};
</script>